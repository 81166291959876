// Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// NPM Modules
import ImageZoom from 'js-image-zoom';
import Swiper from 'swiper';
import {
  Navigation,
  Controller,
  Manipulation,
  Pagination,
  Thumbs,
  FreeMode,
  Scrollbar,
} from 'swiper/modules';

// Cusom Functions
import { screens } from '../modules-custom/functions';

import { W8ShippingAPIClient } from '../../../src/js/modules-custom/W8_ShippingAPIClient';

const client = new W8ShippingAPIClient(
  theme.api.endpointW8,
  theme.api.nonce,
  theme.i18n.locale_pll,
);
document.addEventListener('DOMContentLoaded', function () {
  // Set Sliders
  setSwiperLots();

  // Slider with photos on lot card
  setSwiperLot();

  // Gallery and Zoom on hover
  setSwiperGalleryWithZoom();

  // Comparelist Slider
  seSwipersCompare();

  // Reviews Slider
  setSwiperReviews();

  // Video Slider
  setSwiperVideo();

  // Clients Autos Slider
  setSwiperClientsAutos();

  // Autos Compare Slider
  setSwiperAutosCompare();

  // Footer Autos Slider
  setSwiperFooterAutos();

  setSwiperBrands();
  setCargoTracking();
  setCalcSwiper();

  new Swiper('.swiper-post', {
    modules: [FreeMode, Scrollbar],
    slidesPerView: 'auto',
    // spaceBetween: 24,
    grabCursor: true,
    freeMode: true,
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
  });
});

// Comparelist Slider
document.addEventListener('compare-add', function () {
  seSwipersCompare();
});
document.addEventListener('compare-remove', function () {
  seSwipersCompare();
});

function setCargoTracking() {
  const el = document.getElementById('swiper-cargo-tracking');
  if (!el) {
    return;
  }

  const swiper = new Swiper(el, {
    autoHeight: true,
    oneWayMovement: true,
    allowTouchMove: false,
  });

  document
    .getElementById('swiper-cargo-tracking-back')
    ?.addEventListener('click', () => {
      const currentUrl = window.location.href;
      const urlWithoutQueryParams = currentUrl.split('?')[0];
      history.replaceState(null, '', urlWithoutQueryParams);

      swiper.slidePrev(800);
    });
}

function setCalcSwiper() {
  const el = document.getElementById('swiper-calc');
  if (!el) {
    return;
  }

  const swiper = new Swiper(el, {
    autoHeight: true,
    oneWayMovement: true,
    allowTouchMove: false,
  });

  document.getElementById('swiper-calc-back')?.addEventListener('click', () => {
    const currentUrl = window.location.href;
    const urlWithoutQueryParams = currentUrl.split('?')[0];
    history.replaceState(null, '', urlWithoutQueryParams);

    swiper.slidePrev(800);
  });

  document
    .querySelector('[data-calc]')
    ?.addEventListener('submit', async (e) => {
      e.preventDefault();
      swiper.slideNext(800);
    });

  document.addEventListener('calculated', () => {
    swiper.update();
  });
  document.addEventListener('changeVehicleType', () => {
    swiper.update();
  });
}

function setSwiperLots() {
  document.querySelectorAll('.swiper-lots')?.forEach((el) => {
    const lotID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + lotID);
    const buttonNext = document.getElementById('swiper-button-next-' + lotID);
    const pagination = document.getElementById('swiper-pagination-' + lotID);
    const isOffer = el.classList.contains('swiper-lots-offer');
    const isAutosTo = el.classList.contains('swiper-autos-to');

    if (isAutosTo && window.innerWidth >= screens.lg) {
      return;
    }

    new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      watchSlidesProgress: true,
      spaceBetween: 12,
      speed: 1000,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      breakpoints: {
        640: {
          slidesPerView: 2.2,
        },
        768: {
          slidesPerView: 2.5,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
          // slidesPerGroup: 3,
          // speed: 1600
        },
        1280: {
          slidesPerView: isOffer ? 1.5 : 4,
          spaceBetween: 16,

          // slidesPerGroup: 4,
          // speed: 1600
        },
        1536: {
          slidesPerView: isOffer ? 2 : 4,
          spaceBetween: 16,
          // slidesPerGroup: 4,
          // speed: 1600
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
    });
  });
}

function setSwiperAutosCompare() {
  document.querySelectorAll('.swiper-autos-compare')?.forEach((el) => {
    const lotID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + lotID);
    const buttonNext = document.getElementById('swiper-button-next-' + lotID);
    const pagination = document.getElementById('swiper-pagination-' + lotID);
    const type = el.dataset.type;
    const typeList = el.dataset.typeList;
    const query = el.dataset.query;

    const swiper = new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      watchSlidesProgress: true,
      spaceBetween: 12,
      speed: 1000,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      breakpoints: {
        1024: {
          slidesPerView: theme.i18n.locale == 'kk-KZ' ? 2 : 1.5,
          spaceBetween: 16,
          // slidesPerGroup: 3,
          // speed: 1600
        },
        1280: {
          slidesPerView: theme.i18n.locale == 'kk-KZ' ? 2.5 : 2,
          spaceBetween: 16,
          // slidesPerGroup: 4,
          // speed: 1600
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
      on: {
        slideChange: function () {
          if (this.isLoadedLazySlides) {
            return;
          }
          updateSwiper(swiper, type, query, typeList);
          this.isLoadedLazySlides = true;
        },
      },
    });
  });
}

function updateSwiper(swiper, type, query, typeList = null) {
  const event_name = 'swiper-' + type + '-updated';

  const event = new CustomEvent(event_name);

  client.post('getSwiperItems', {
    body: {
      type: type,
      typeList: typeList,
      query: query,
    },
    onSuccess: ({ items }) => {
      if (items.length) {
        swiper.slidesEl
          .querySelectorAll('.swiper-slide.skeleton')
          .forEach((slide, index) => {
            const container = document.createElement('div');
            container.innerHTML = items[index];
            const newSlide = container.querySelector(`.swiper-slide`);
            swiper.slidesEl.replaceChild(newSlide, slide);
          });
        swiper.update();
        document.dispatchEvent(event);
      }
      swiper.enable();
    },
  });
}

function setSwiperReviews() {
  document.querySelectorAll('.swiper-reviews')?.forEach((el) => {
    const ID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + ID);
    const buttonNext = document.getElementById('swiper-button-next-' + ID);
    const pagination = document.getElementById('swiper-pagination-' + ID);
    const type = el.dataset.type;
    const typeList = el.dataset.typeList;
    const query = el.dataset.query;

    const swiper = new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      watchSlidesProgress: true,
      spaceBetween: 8,
      speed: 1000,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
      on: {
        slideChange: function () {
          const showMore =
            this.slides[this.previousIndex].querySelector(
              '.ShowMoreLC',
            )?.showMore;
          if (showMore && window.innerWidth <= 640) {
            showMore.hideContent();
          }
          if (this.isLoadedLazySlides) {
            return;
          }
          updateSwiper(swiper, type, query, typeList);
          this.isLoadedLazySlides = true;
        },
      },
    });
  });
}

function setSwiperBrands() {
  document.querySelectorAll('.swiper-brands')?.forEach((el) => {
    const ID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + ID);
    const buttonNext = document.getElementById('swiper-button-next-' + ID);

    new Swiper(el, {
      modules: [Navigation, Manipulation],
      grabCursor: true,
      spaceBetween: 12,
      slidesPerView: 'auto',
      speed: 1000,
      breakpoints: {
        640: {
          spaceBetween: 24,
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
    });
  });
}

function setSwiperVideo() {
  document.querySelectorAll('.swiper-video')?.forEach((el) => {
    const ID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + ID);
    const buttonNext = document.getElementById('swiper-button-next-' + ID);
    const pagination = document.getElementById('swiper-pagination-' + ID);

    new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      watchSlidesProgress: true,
      spaceBetween: 8,
      speed: 1000,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
    });
  });
}

function setSwiperClientsAutos() {
  document.querySelectorAll('.swiper-clients-autos')?.forEach((el) => {
    const ID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + ID);
    const buttonNext = document.getElementById('swiper-button-next-' + ID);
    const pagination = document.getElementById('swiper-pagination-' + ID);
    const type = el.dataset.type;
    const typeList = el.dataset.typeList;
    const query = el.dataset.query;

    const swiper = new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      watchSlidesProgress: true,
      spaceBetween: 8,
      // autoHeight: true,
      speed: 500,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
      on: {
        slideChange: function () {
          if (this.isLoadedLazySlides) {
            return;
          }
          updateSwiper(swiper, type, query, typeList);
          this.isLoadedLazySlides = true;
        },
      },
    });
  });
}

function setSwiperFooterAutos() {
  document.querySelectorAll('.swiper-footer-autos')?.forEach((el) => {
    const ID = el.dataset.id;
    const buttonPrev = document.getElementById('swiper-button-prev-' + ID);
    const buttonNext = document.getElementById('swiper-button-next-' + ID);
    const pagination = document.getElementById('swiper-pagination-' + ID);

    new Swiper(el, {
      modules: [Navigation, Manipulation, Pagination],
      grabCursor: true,
      pagination: {
        el: pagination,
        type: 'fraction',
      },
      watchSlidesProgress: true,
      slidesPerView: 1.2,
      spaceBetween: 18,
      // autoHeight: true,
      speed: 500,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 2.5,
        },
        1280: {
          slidesPerView: 3.2,
        },
        1536: {
          slidesPerView: 4,
        },
      },
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
    });
  });
}

function setSwiperLot() {
  document.querySelectorAll('.swiper-lot')?.forEach((el) => {
    const lotID = el.dataset.lotId;
    const pagination = document.getElementById('swiper-pagination-' + lotID);
    const buttonPrev = document.getElementById('swiper-button-prev-' + lotID);
    const buttonNext = document.getElementById('swiper-button-next-' + lotID);

    new Swiper(el, {
      modules: [Pagination, Navigation],
      cssMode: true,
      mousewheel: true,
      navigation: {
        prevEl: buttonPrev,
        nextEl: buttonNext,
        hiddenClass: 'opacity-50',
      },
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
      },
    });
  });
}

function setSwiperGalleryWithZoom() {
  let imageZoom;

  const thumb = new Swiper(document.getElementById('lot-gallery-thumb'), {
    modules: [FreeMode, Thumbs],
    slidesPerView: 4,
    spaceBetween: 2,
    freeMode: true,
    watchSlidesProgress: true,
  });

  const swiper = new Swiper(document.getElementById('lot-gallery'), {
    modules: [Navigation, Thumbs, Pagination],
    spaceBetween: 12,
    navigation: {
      nextEl: '.auction-car-gallery-next',
      prevEl: '.auction-car-gallery-prev',
      hiddenClass: 'hidden',
    },
    pagination: {
      modifierClass: 'single-lot-swiper-pagination-',
      el: document.getElementById('swiper-pagination-lot-gallery'),
      type: 'fraction',
    },
    thumbs: {
      swiper: thumb,
    },
    on: {
      init: zoom,
    },
  });

  function zoom(swiper) {
    if (window.innerWidth < screens.lg) {
      return;
    }
    imageZoom?.kill();
    imageZoom = new ImageZoom(swiper.slides[swiper.realIndex], {
      zoomContainer: document.getElementById('info-lot'),
      width: swiper.slides[swiper.realIndex].clientWidth,
      zoomPosition: 'original',
    });
  }

  swiper.on('slideChangeTransitionEnd', zoom);
}

function seSwipersCompare() {
  const updateSwiperSlides = (swiper) => {
    const currentBreakpoint = swiper.currentBreakpoint || 'max';
    const slidesPerView =
      currentBreakpoint === 'max'
        ? swiper.params.slidesPerView
        : swiper.params.breakpoints[currentBreakpoint].slidesPerView;

    let slidesCount = swiper.slidesEl.querySelectorAll(
      '.swiper-slide[data-slider-id]',
    ).length;

    if (slidesCount < slidesPerView) {
      // Minimum slides per view
      if (+currentBreakpoint >= 640 && slidesCount < 2) {
        slidesCount = 2;
      }
      swiper.params.slidesPerView = slidesCount;
    }

    swiper.update();
  };

  // sync all sliders
  let isSwiping = false; // to prevent recursive loop
  document.compareSwipers = [];

  document.querySelectorAll('.swiper-main')?.forEach((el, index) => {
    new Swiper(el, {
      // Your Swiper settings here
      // init: false,
      modules: [Navigation, Controller, Manipulation], // Ensure you include the Controller module
      slidesPerView: 1.7,
      spaceBetween: index == 0 ? 6 : 0,
      mobileFirst: true,
      // cssMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2.2,
          spaceBetween: index == 0 ? 10 : 0,
        },
        768: {
          slidesPerView: 2.5,
          spaceBetween: index == 0 ? 16 : 0,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: index == 0 ? 16 : 0,
          noSwiping: true,
          noSwipingClass: 'swiper-slide',
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: index == 0 ? 16 : 0,
          noSwiping: true,
          noSwipingClass: 'swiper-slide',
        },
      },
      navigation: {
        nextEl: index == 0 ? '.swiper-btn-next' : null,
        prevEl: index == 0 ? '.swiper-btn-prev' : null,
        hiddenClass: 'hidden',
      },
      on: {
        init: function (swiper) {
          document.compareSwipers.push(this);
          updateSwiperSlides(swiper);
        },
        slideChange: function () {
          if (!isSwiping) {
            const activeIndex = this.activeIndex;
            isSwiping = true;
            document.compareSwipers.forEach((sw) => {
              if (sw !== this) {
                sw.slideTo(activeIndex);
              }
            });
            isSwiping = false;
          }
        },
        breakpoint: function (swiper) {
          updateSwiperSlides(swiper);
        },
      },
    });
  });
}

// document.addEventListener("facetwp-refresh", function () {
// 	setSwiperLot();
// })

document.addEventListener('facetwp-loaded', function () {
  setSwiperLot();
});
